import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { FaTwitter, FaInstagram, FaFacebookF } from "react-icons/fa";

import { rhythm } from "../utils/typography";
import PodcastLinks from "./podcastLinks";

function Layout({ children }) {
  const {
    site: {
      siteMetadata: { title, author, social },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            social {
              facebook
              twitter
              instagram
            }
          }
        }
      }
    `
  );

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>
        <div style={{ display: `flex`, justifyContent: `space-between` }}>
          <h3
            style={{
              fontFamily: `Montserrat, sans-serif`,
              marginTop: 0,
              fontSize: `2rem`,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h3>
          <ul style={{ display: `flex`, justifyContent: `space-between`, listStyle: `none` }}>
            <li style={{ marginLeft: `10px`, display: social.facebook ? "list-item" : "none" }}>
              <a
                href={`https://www.facebook.com/${social.facebook}`}
                aria-label="Facebook"
                rel="noopener noreferrer"
                target="_blank"
                style={{ boxShadow: `none`, color: "grey" }}
              >
                <FaFacebookF />
              </a>
            </li>
            <li style={{ marginLeft: `10px`, display: social.twitter ? "list-item" : "none" }}>
              <a
                href={`https://twitter.com/${social.twitter}`}
                aria-label="Twitter"
                rel="noopener noreferrer"
                target="_blank"
                style={{ boxShadow: `none`, color: "grey" }}
              >
                <FaTwitter />
              </a>
            </li>
            <li style={{ marginLeft: `10px`, display: social.instagram ? "list-item" : "none" }}>
              <a
                href={`https://www.instagram.com/${social.instagram}`}
                aria-label="Instagram"
                rel="noopener noreferrer"
                target="_blank"
                style={{ boxShadow: `none`, color: "grey" }}
              >
                <FaInstagram />
              </a>
            </li>
          </ul>
        </div>
        <PodcastLinks />
      </header>
      <main>{children}</main>
      <footer>
        <div>
          © {new Date().getFullYear()}, {author}
        </div>
      </footer>
    </div>
  );
}

export default Layout;
