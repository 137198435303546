import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { FaPodcast, FaSpotify, FaRss, FaYoutube } from "react-icons/fa";

const iTunesColor = `rgb(131,65,194)`;
const YoutubeColor = `rgb(235,51,35)`;
const spotifyColor = `rgb(85,175,93)`;
const rssColor = `rgb(222,115,56)`;
function PodcastLinks() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            podcast {
              externalFeeds {
                apple
                youtube
                spotify
              }
              output
            }
          }
        }
      }
    `
  );
  const podcast = site.siteMetadata.podcast;

  return (
    <ul style={{ display: `flex`, flexWrap: `wrap`, justifyContent: `space-between`, listStyle: `none`, padding: 0 }}>
      <li style={{ display: podcast.externalFeeds.apple ? "list-item" : "none" }}>
        <a href={podcast.externalFeeds.apple} style={{ boxShadow: `none`, color: iTunesColor, fontWeight: `bold` }}>
          <FaPodcast />
          <span style={{ margin: `0 5px` }}>iTunes</span>
        </a>
      </li>
      <li style={{ display: podcast.externalFeeds.youtube ? "list-item" : "none" }}>
        <a href={podcast.externalFeeds.youtube} style={{ boxShadow: `none`, color: YoutubeColor, fontWeight: `bold` }}>
          <FaYoutube />
          <span style={{ margin: `0 5px` }}>YouTube</span>
        </a>
      </li>
      <li style={{ display: podcast.externalFeeds.spotify ? "list-item" : "none" }}>
        <a href={podcast.externalFeeds.spotify} style={{ boxShadow: `none`, color: spotifyColor, fontWeight: `bold` }}>
          <FaSpotify />
          <span style={{ margin: `0 5px` }}>Spotify</span>
        </a>
      </li>

      <li>
        <a href="/rss.xml" style={{ boxShadow: `none`, color: rssColor, fontWeight: `bold` }}>
          <FaRss />
          <span style={{ margin: `0 5px` }}>RSS</span>
        </a>
      </li>
    </ul>
  );
}

export default PodcastLinks;
